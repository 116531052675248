import React from 'react';

import CollectionTableItem from 'components/collection-portfolio-item/collection-table-item.component';

import {
    Container,
    Row,
    Col,
    Table
} from 'reactstrap';

const CollectionTablePreview = ({category, routename, portfolioitems}) => (
    <div className="section section-gray">
      <Container>
        <Row>
          <Col md="12">
            <h4 className="title"><a href={'/portfolio/' + routename}>{category}</a></h4>
          </Col>
          <Col className="ml-auto mr-auto" md="12">
          <div className="table-responsive">
            <Table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center"><strong>Title</strong></th>
                    <th className="text-right"><strong>Publisher</strong></th>
                    <th className="text-right"><strong>Contribution</strong></th>
                  </tr>
                </thead>
                <tbody>
                  {
                      portfolioitems
                      .map((portfolioitem) => (
                          <CollectionTableItem key={portfolioitem.id} portfolioitem={portfolioitem}/>
                      ))
                  }   
                </tbody>
              </Table>
            </div>
            </Col>
          </Row>
        </Container>
    </div>
);

export default CollectionTablePreview;