import React from 'react';

const AwardItem = ({ gamerelease, grantedby, awardname, year }) => (
    <>
        <tr>
            <td className="td-product">
                <strong>{gamerelease}</strong>
            </td>
            <td className="td-product">
                <small>{grantedby}</small>
            </td>
            <td className="td-product">
                <small>{awardname}</small>
            </td>
            <td className="td-number">
                <small>{year}</small>
            </td>
        </tr>
    </>
);

export default AwardItem;