import React from 'react';
import './sidebar-card.styles.css';

const SidebarCard = (props) => {
    return (
        <div className="sidebarCard" style={{width:props.width ? props.width : '100%'}} {...props}>
            {props.children}
        </div>
    )
};

export default SidebarCard;