const AWARD_DATA =  
[
    {
      "id": 1,
      "gamerelease": "Unlimited Adventures",
      "grantedby": "SSI",
      "awardname": "Grand Prize (Best Overall Dungeon Design)",
      "year": 1994
    },
    {
      "id": 2,
      "gamerelease": "Dragon Magazine",
      "grantedby": "Origins",
      "awardname": "Best Periodical",
      "year": 2003
    },
    {
      "id": 3,
      "gamerelease": "Advanced Bestiary",
      "grantedby": "Monte Cook Presents",
      "awardname": "Excellence",
      "year": 2005
    },
    {
      "id": 4,
      "gamerelease": "Dragon Compendium, Vol 1",
      "grantedby": "ENnies",
      "awardname": "Best Supplement; Gold",
      "year": 2006
    },
    {
      "id": 5,
      "gamerelease": "D&D Icons: Colossal Red Dragon",
      "grantedby": "Origins",
      "awardname": "Best Minatures Line",
      "year": 2006
    },
    {
      "id": 6,
      "gamerelease": "D&D Icons: Legend of Drizzt",
      "grantedby": "ENnies",
      "awardname": "Best Minis; Gold",
      "year": 2008
    },
    {
      "id": 7,
      "gamerelease": "Dungeons & Dragons 4th Edition",
      "grantedby": "ENnies",
      "awardname": "Product of the Year, Best Game; Gold",
      "year": 2009
    },
    {
      "id": 8,
      "gamerelease": "Monster Manual",
      "grantedby": "ENnies",
      "awardname": "Best Monster or Adversary; Gold",
      "year": 2009
    },
    {
      "id": 9,
      "gamerelease": "Player's Handbook",
      "grantedby": "ENnies",
      "awardname": "Best Rules; Gold",
      "year": 2009
    },
    {
      "id": 10,
      "gamerelease": "Player's Handbook 3",
      "grantedby": "ENnies",
      "awardname": "Best Supplement; Silver",
      "year": 2010
    },
    {
      "id": 11,
      "gamerelease": "Dungeons & Dragons Miniatures",
      "grantedby": "ENnies",
      "awardname": "Best Minis; Gold",
      "year": 2010
    },
    {
      "id": 12,
      "gamerelease": "Monster Vault",
      "grantedby": "ENnies",
      "awardname": "Best Monster or Adversary; Silver",
      "year": 2011
    },
    {
      "id": 13,
      "gamerelease": "Lords of Waterdeep",
      "grantedby": "ENnies",
      "awardname": "Best RPG Related Product",
      "year": 2012
    },
    {
      "id": 14,
      "gamerelease": "Lords of Waterdeep",
      "grantedby": "Guldbrikken",
      "awardname": "Special Jury Prize Winner",
      "year": 2012
    },
    {
      "id": 15,
      "gamerelease": "Lords of Waterdeep: Scoundrels of Skullport",
      "grantedby": "Golden Geek",
      "awardname": "Best Board Game Expansion",
      "year": 2013
    },
    {
      "id": 16,
      "gamerelease": "Lords of Waterdeep",
      "grantedby": "Origins",
      "awardname": "Best Board Game",
      "year": 2013
    },
    {
      "id": 17,
      "gamerelease": "Lords of Waterdeep iOS",
      "grantedby": "BoardGameGeek.com",
      "awardname": "Reader's Choice Game of the Year ",
      "year": 2014
    },
    {
      "id": 18,
      "gamerelease": "Lords of Waterdeep: Scoundrels of Skullport",
      "grantedby": "Dice Tower Awards",
      "awardname": "Best Expansion",
      "year": 2014
    },
    {
      "id": 19,
      "gamerelease": "Dungeons & Dragons 5th Edition Starter Set",
      "grantedby": "Golden Geek",
      "awardname": "Best Supplement",
      "year": 2014
    },
    {
      "id": 20,
      "gamerelease": "Dungeons & Dragons 5th Edition",
      "grantedby": "Golden Geek",
      "awardname": "Game of the Year",
      "year": 2014
    },
    {
      "id": 21,
      "gamerelease": "Player's Handbook",
      "grantedby": "ENnies",
      "awardname": "Best Game; Gold",
      "year": 2015
    },
    {
      "id": 22,
      "gamerelease": "Monster Manual",
      "grantedby": "ENnies",
      "awardname": "Best Monster or Adversary; Gold",
      "year": 2015
    },
    {
      "id": 23,
      "gamerelease": "Player's Handbook",
      "grantedby": "ENnies",
      "awardname": "Best Rules; Gold",
      "year": 2015
    },
    {
      "id": 24,
      "gamerelease": "Dungeon Master's Guide",
      "grantedby": "ENnies",
      "awardname": "Best Supplement; Gold",
      "year": 2015
    },
    {
      "id": 25,
      "gamerelease": "Player's Handbook",
      "grantedby": "ENnies",
      "awardname": "Best Writing; Silver",
      "year": 2015
    },
    {
      "id": 26,
      "gamerelease": "Player's Handbook",
      "grantedby": "ENnies",
      "awardname": "Product of the Year; Gold",
      "year": 2015
    },
    {
      "id": 27,
      "gamerelease": "Player's Handbook",
      "grantedby": "Origins",
      "awardname": "Best Roleplaying Game Supplement",
      "year": 2015
    },
    {
      "id": 28,
      "gamerelease": "Monster Manual",
      "grantedby": "Origins",
      "awardname": "Best Roleplaying Game",
      "year": 2015
    },
    {
      "id": 29,
      "gamerelease": "Out of the Abyss",
      "grantedby": "ENnies",
      "awardname": "Judges' Spotlight Winner",
      "year": 2016
    },
    {
      "id": 30,
      "gamerelease": "Tales from the Yawning Portal",
      "grantedby": "ENnies",
      "awardname": "Judges' Spotlight Winner",
      "year": 2017
    },
    {
      "id": 31,
      "gamerelease": "Xanathar's Guide to Everything",
      "grantedby": "Golden Geek",
      "awardname": "RPG Best Artwork and Presentation",
      "year": 2017
    },
    {
      "id": 32,
      "gamerelease": "Mordenkainen's Tome of Foes",
      "grantedby": "Origins",
      "awardname": "Best Roleplaying Game Supplement",
      "year": 2019
    },
    {
      "id": 33,
      "gamerelease": "dScryb",
      "grantedby": "ENnies",
      "awardname": "Best Online Content; Silver",
      "year": 2021
    }
];

export default AWARD_DATA;
