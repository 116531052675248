import React from 'react';

import { connect } from 'react-redux';

import { selectAwardCollection } from 'redux/awards/awards.selectors';
import { createStructuredSelector } from 'reselect';

import AwardItem from 'components/award/award-item.component';
import IndividualPortfolioPageHeader from 'components/header/individual-portfolio-page-header';

import {
    Container,
    Row,
    Col,
    Table
} from 'reactstrap';

const AwardPage = ({ collection }) => {
    return (
        <>
        <IndividualPortfolioPageHeader 
            pageTitle="Awards" 
            displayImage="/images/awards/Header.jpg" 
            generalDescription="Over the years, many of the projects I’ve worked on have been nominated for various awards. Some of them even won! Here’s a list of all the winners I could remember."
            />
            {/* <div
                className="page-header page-header-small"
                style={{
                backgroundImage:
                    "url(" +
                    require("assets/img/header/dkjcwcxwh1825_DnD_Header.jpg").default +
                    ")",
                }}
            >
                <div className="filter" />
                <div className="content-center">
                    <div className="motto">
                        <h1 className="title"><strong>Awards</strong></h1>
                        <h3 className="description">Over the years, many of the projects I’ve worked on have been nominated for various awards. Some of them even won! Here’s a list of all the winners I could remember.</h3>
                    </div>
                </div>
            </div> */}
            <div className="section-gray">
                <Container>
                    <Row>
                    <Col className="ml-auto mr-auto" md="12">
                        <Table className="table-shopping" responsive striped>
                            <thead>
                            <tr>
                                <th className="text-left">Work</th>
                                <th className="text-left"><strong>Granted By</strong></th>
                                <th className="text-left"><strong>Award Category</strong></th>
                                <th className="text-right"><strong>Year</strong></th>
                            </tr>
                            </thead>
                            <tbody>
                                {collection
                                .slice(0)
                                .reverse()
                                .map(({ id, ...otherSectionProps }) => (
                                    <AwardItem key={id} {...otherSectionProps} />
                                ))}
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                    </Container>
            </div>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    collection: selectAwardCollection
});

export default connect(mapStateToProps)(AwardPage);