import React from 'react';

import ServicesHeader from 'components/header/services-page-header.component';

import {IoGameController} from "react-icons/io5";
import {GiTreasureMap} from "react-icons/gi";
import {GiOldMicrophone} from "react-icons/gi";
import {GiSpellBook} from "react-icons/gi";
import {FaLaptopMedical} from "react-icons/fa";
import {GiRuleBook} from "react-icons/gi";

// reactstrap components
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
  } from "reactstrap";

const ServicesPage = () => {
    return (
     <div>
     <ServicesHeader />
        <div>
          <div className="project-4 section section-dark">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Services</h2>
                  <h5 className="description">
                  I don't know you. I don't know what you want. If you are looking for a ransom, I can tell you, I don't have money. But what I do have are a very particular set of skills. Skills I've acquired over a very long career. Skills that make me… like Liam Neeson in <em>Taken</em>, but a writer for games instead of all that shooty-punchy stuff.
                  </h5>
                </Col>
              </Row>
              <div className="space-top" />
              <Row id="gwd_anchor">
                <Col className="ml-auto" md="5">
                  <Card
                    data-background="image"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/services/Header.png")
                          .default +
                        ")",
                    }}
                  >
                    <div className="icon icon-danger">
                    <IoGameController size="64px" />
                    </div>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                          Game Writing & Narrative Design
                        </CardTitle>
                      </a>
                      <p className="card-description">
                      I play games for story, and so that’s how I write them. Yet story in a game isn’t just putting compelling characters through three-act structures. Just as a forest is made of trees, a game’s story is all its details: item names, dialogue barks, journal entries, and other ephemera. I see forest and trees. I know where the world and story take people and how each detail contributes to that journey.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br />
              <hr />
              <br />
              <Row id="wb_anchor">
                <Col className="ml-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                          World Building
                        </CardTitle>
                      </a>
                      <p className="card-description">
                      I know how to create compelling and convincing worlds. I’ve seen how the sausage is made for some of the world’s biggest brands, and I’ve been in the kitchen turning the grinder. My experiences have given me a unique perspective. They’ve opened my eyes to the implicit and explicit rules of fictional worlds—the principles created by the things said and unsaid. I can keep you from building traps for yourself, and help you make the kind of world that causes fans to fall in love.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    data-background="image"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/services/WorldBuilding.jpg").default +
                        ")",
                    }}
                  >
                    <div className="icon icon-danger">
                        <GiTreasureMap size="64px" />   
                    </div>
                  </Card>
                </Col>
              </Row>
              <br />
              <hr />
              <br />
              <Row id="dvd_anchor">
                <Col className="ml-auto" md="5">
                  <Card
                    data-background="image"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/services/DialogueandVODirection.png")
                          .default +
                        ")",
                    }}
                  >
                    <div className="icon icon-danger">
                     <GiOldMicrophone size="64px" />
                    </div>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                            Dialogue & VO Direction
                        </CardTitle>
                      </a>
                      <p className="card-description">
                        When you hear great dialogue in a game, it was more than good writing and fine acting. Someone helped the voice actor hit the right emotional notes, and someone cut the dialog recordings together, so their performances delivered on the promise of the story beat. Having a writer present gives you a force multiplier in that process, and if something that worked on the page doesn’t work in the studio, you’ll have the expert on hand to course correct in a way that keeps everything in synch. 
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br />
              <hr />
              <br />
              <Row id="sc_anchor">
                <Col className="ml-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                            Story Consultation
                        </CardTitle>
                      </a>
                      <p className="card-description">
                        The process of creating story for a game inevitably gets messy. Team members come and go, goals change, plans evolve, and things as fundamental as the game’s mechanics and level design rarely finalize until ship date. When you need someone to come in and clarify the chaos, give me a call. I’ll view it from a thousand feet and crawl through the weeds so I can help you understand your options to get the best story out of your game.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    data-background="image"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/services/unnamed.jpg").default +
                        ")",
                    }}
                  >
                    <div className="icon icon-danger">
                         <GiSpellBook size="64px" />
                    </div>
                  </Card>
                </Col>
              </Row>
              <br />
              <hr />
              <br />
              <Row id="sed_anchor">
                <Col className="ml-auto" md="5">
                  <Card
                    data-background="image"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/services/ScriptEditingDoctoring.png")
                          .default +
                        ")",
                    }}
                  >
                    <div className="icon icon-danger">
                        <FaLaptopMedical size="64px" />
                    </div>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                            Script Editing & Doctoring
                        </CardTitle>
                      </a>
                      <p className="card-description">
                        Sometimes you’re so close to the finish you can taste it. You just need a little help getting over the line. I’m an experienced copy editor and managing editor with an ear for dialogue, deep understanding of story, and comprehensive comprehension of play. Whether in Final Draft form, Twine, an excel sheet, or your proprietary tool—whether it needs a little zhuzh or serious surgery—I can make your script shine.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br />
              <hr />
              <br />
              <Row id="bgtw_anchor">
                <Col className="ml-auto" md="5">
                  <Card className="card-plain">
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <CardTitle tag="h3">
                            Board Game Technical Writing
                        </CardTitle>
                      </a>
                      <p className="card-description">
                        We’ve all done this thing: You sit down, crack the plastic wrap on a super exciting board game, and then play with your phone for an hour while some brave soul leaps on the grenade—the titanic task of reading the rules. That thing? Yeah, I hate that. Like, fiery-passion-of-a-thousand-hells hate. There must be a better way, right? There is. Hire me. When working on Funkoverse, I took 20 pages of D&D-dense rules written in Word and turned it into six breezy, illustrated half pages that had players playing the full game within twenty minutes. Let me help you. Generations of gamers will have fuller phone batteries if you do.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    data-background="image"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/services/BoardGameTechnicalWriting.png").default +
                        ")",
                    }}
                  >
                    <div className="icon icon-danger">
                        <GiRuleBook size="64px" />
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
    </div>
)};

export default ServicesPage;
