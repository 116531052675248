import React from 'react';
import DOMPurify from 'dompurify';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    Col,
} from 'reactstrap';

function CollectionPortfolioItem ({ portfolioitem }) {
    const { title, publisher, platform, description, imageUrl, destinationUrl, buttonText } = portfolioitem;

return (
        <>
              <Col md="4" sm="6">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                      <img
                        alt="..."
                        src={imageUrl}
                      />
                  </CardImg>
                  <CardBody className="text-center">
                    <h6 className="card-category">{platform}</h6>
                    <CardTitle tag="h4">
                      {title}
                    </CardTitle>

                      <p className="card-description">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description)}} />
                      </p>
                  </CardBody>
                  <CardFooter className="card-category">
                    {publisher}
                    <br />
                    <br />
                    {destinationUrl !== 'null' ? 
                    <a href={destinationUrl} target="_blank" rel="noopener noreferrer">
                        <Button
                            className="btn-round"
                            color="danger"
                        >
                        {buttonText}
                        </Button>
                    </a>
                    : <span></span>
                    }
                  </CardFooter>
                </Card>
              </Col>
        </>
    );
}

export default CollectionPortfolioItem;
