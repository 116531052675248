import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

import portfolioReducer from "./portfolio/portfolio.reducer";
import awardReducer from "./awards/awards.reducer";
import blogReducer from "./blog/blog.reducer";
import currentReducer from "./current/current.reducer";


const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
};

const rootReducer = combineReducers({
    portfolio: portfolioReducer,
    award: awardReducer,
    blog: blogReducer,
    current: currentReducer,
});

export default persistReducer(persistConfig, rootReducer);
