import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';

import App from './App';

import { store } from './redux/store';

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/react-demo.css";

import ScrollToTop from './components/hoc/scrolltotop.component';

ReactDOM.render(
  <Provider store={ store }>
       <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop> 
       </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
