/* eslint eqeqeq: 0 */
/*eslint-disable*/
import React, { useState } from 'react';
import './pagination.styles.css';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectBlogCollection } from '../../redux/blog/blog.selectors';

import { NavLink } from 'react-router-dom';

const renderData = data => {
    return (
        <>
                {data.map(post => {
                    return (
                        <NavLink key={post.id} to={`/posts/${post.id}`}>
                            <div className="recentPost">
                                <h3>{post.blogtitle}</h3>
                                <span>{post.postedon}</span>
                            </div>
                        </NavLink>
                    );
                })}
        </>
      
    )
}

function PaginationComponent ({blogItems}) {
    //console.log(blogItems);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const [pageNumberLimit, setPageNumberLimit] = useState(3);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);


    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id));
    };

    const pages = [];
    for(let i=1; i<=Math.ceil(blogItems.length/itemsPerPage); i++){
        pages.push(i);
    }

    const indexOfLastItem = currentPage*itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = blogItems.slice(indexOfFirstItem, indexOfLastItem);

    const renderPageNumbers = pages.map(number => {
        if(number < maxPageNumberLimit+1 && number>minPageNumberLimit){
            return (
                <li key={number} 
                    id={number} 
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });

    const handleNextbtn = () => {
        setCurrentPage(currentPage+1);

        if(currentPage+1 > maxPageNumberLimit)
        {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevbtn = () => {
        setCurrentPage(currentPage-1);

        if((currentPage - 1) % pageNumberLimit==0)
        {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }

    console.log(blogItems.length);
    return (
        <>
        <div className="recentPosts">
        {renderData(currentItems)}
        {
            blogItems.length > 3 ? 
        
        <ul className="pageNumbers">
            <li>
                <button onClick={handlePrevbtn}
                    disabled={currentPage == pages[0] ? true : false}
                > 
                    <i
                     aria-hidden={true}
                     className="fa fa-angle-double-left"
                    />
                </button>
            </li>
             {pageDecrementBtn}
             {renderPageNumbers}
             {pageIncrementBtn}
             <li>
                 <button onClick={handleNextbtn}
                    disabled={currentPage == pages[pages.length - 1] ? true : false}
                 >  
                    <i
                     aria-hidden={true}
                     className="fa fa-angle-double-right"
                    />
                 </button>
             </li>
        </ul>
        : null 
        } 
        {/* <ul>
             */}
            {/* {
                blogItems.map(post => {
                    return (
                        <li key={post.id}>{post.blogtitle}</li>
                    );
                })
            } */}
        {/* </ul> */}
        </div>  
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    blogItems: selectBlogCollection
});

export default connect(mapStateToProps)(PaginationComponent);

