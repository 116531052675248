import React from 'react';
import './blogpost-item.styles.css';
import DOMPurify from 'dompurify';

import {
    Badge,
    Card,
    CardTitle,
    CardBody,
} from 'reactstrap';

const BlogpostItem = ({blogtitle, blogcategory, postedon, author, headerblogimage, page1, page2, page3, page4}) => (
    <>
    <Card className="card-blog card-plain text-center">
        <div className="card-image">
                <img
                alt="..."
                className="imgCardResize"
                src={headerblogimage}
                />
        </div>
        <CardBody>
            <div className="card-category">
                <Badge className="main-tag" color="primary">
                    {blogcategory}
                </Badge>
            </div>
                <CardTitle tag="h3">
                    {blogtitle}
                </CardTitle>
            <div className="card-description">
                <p className="postDesc">
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page1)}} />
                </p>
            </div>
        </CardBody>
    </Card>
    </>
);

export default BlogpostItem;