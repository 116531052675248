import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import {IoGameController} from "react-icons/io5";
import {GiTreasureMap} from "react-icons/gi";
import {GiOldMicrophone} from "react-icons/gi";
import {GiSpellBook} from "react-icons/gi";
import {FaLaptopMedical} from "react-icons/fa";
import {GiRuleBook} from "react-icons/gi";


// core components

function SectionServices() {
  return (
    <>
      <div id="services_anchor" className="section section-summary">
        <Container>
        <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Services</h2>
                <h5 className="description">
                I’m an expert in the fantasy and sci-fi genres, a history buff, and a font of knowledge about all the weird and occult bits of our crazy world.
                <br />
                <br />
                I know what makes fictional worlds work. I can spot when they’re not firing on all cylinders, and I can tell you why. More than that, I know when it doesn’t matter. So, whether your story needs a tune up, a custom build, or just enough to keep it together to get you safely home, I’ve got the tools and the know-how to steer you right.
                </h5>
              </Col>
            </Row>
          <Row>
            <Col md="2">
              <div className="info">
                <div style={{textAlign: 'center'}}>
                <Link to="/services#gwd_anchor" className="icon icon-danger"><IoGameController /></Link>
                   {/* <a href="https://www.mattsernett.com/services/#gwd_anchor" target="_self" className="icon icon-danger" ><IoGameController /></a>  */}
                </div>
                <div className="description">
                  <h4 className="info-title">Game Writing & Narrative Design</h4>
                 
                </div>
              </div>
            </Col>
            <Col md="2">
              <div className="info">
              <div style={{textAlign: 'center'}}>
              <Link to="/services#wb_anchor" className="icon icon-danger"><GiTreasureMap /></Link>
                   {/* <a href="https://www.mattsernett.com/services#wb_anchor" target="_self" className="icon icon-danger" ><GiTreasureMap /></a>  */}
                </div>
                <div className="description">
                  <h4 className="info-title">World Building</h4>
                 
                </div>
              </div>
            </Col>
            <Col md="2">
              <div className="info">
              <div style={{textAlign: 'center'}}>
              <Link to="/services#dvd_anchor" className="icon icon-danger"><GiOldMicrophone /></Link>
                   {/* <a href="https://www.mattsernett.com/services/#dvd_anchor" target="_self" className="icon icon-danger" ><GiOldMicrophone /></a>  */}
                </div>
                <div className="description">
                  <h4 className="info-title">Dialogue & VO Direction</h4>
                  
                </div>
              </div>
            </Col>
            <Col md="2">
              <div className="info">
              <div style={{textAlign: 'center'}}>
              <Link to="/services#sc_anchor" className="icon icon-danger"><GiSpellBook /></Link>
                   {/* <a href="https://www.mattsernett.com/services/#sc_anchor" target="_self" className="icon icon-danger" > <GiSpellBook /></a>  */}
                </div>
                <div className="description">
                  <h4 className="info-title">Story Consultation</h4>
                 
                </div>
              </div>
            </Col>
            <Col md="2">
              <div className="info">
              <div style={{textAlign: 'center'}}>
                <Link to="/services#sed_anchor" className="icon icon-danger"><FaLaptopMedical /></Link>
                   {/* <a href="https://www.mattsernett.com/services/#sed_anchor" target="_self" className="icon icon-danger" ><FaLaptopMedical /></a>  */}
                </div>
                <div className="description">
                  <h4 className="info-title">Script Editing & Doctoring</h4>
                  
                </div>
              </div>
            </Col>
            <Col md="2">
              <div className="info">
              <div style={{textAlign: 'center'}}>
              <Link to="/services#bgtw_anchor" className="icon icon-danger"><GiRuleBook /></Link>
                   {/* <a href="https://www.mattsernett.com/services/#bgtw_anchor" target="_self" className="icon icon-danger" ><GiRuleBook /></a>  */}
                </div>
                <div className="description">
                  <h4 className="info-title">Board Game Technical Writing</h4>
                 
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionServices;
