import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function SectionCards() {
  return (
    <>
      <div id="clients_anchor" className="section section-cards section-dark">
        <div className="codrops-header">  
          <Row>
            <Col lg="4" md="6">
              <div className="section-description">
                <h3 className="title">Clients</h3>
                {/* <h6 className="category"> Lorem ipsum dolor sit amet</h6> */}
                <h5 className="description">
                Whether for a billion-dollar company or a scrappy startup, I deliver high-quality creative content and expert advice. Most of the time, I knock it out of the park on the first swing, but I’m not precious about my work, so if it’s just an infield hit, I’ll work with you to give you a winning game. 
                </h5>
              </div>
            </Col>
          </Row>
        </div>
        <section className="section-intro">
          <div className="isolayer isolayer--deco1 isolayer--shadow js">
            <ul className="grid grid--loaded">
              <li className="grid__item first-card">
                <a
                  className="grid__link"
                  href="https://www.beamdog.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/Beamdog.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item second-card">
                <a
                  className="grid__link"
                  href="https://blazesky.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/BlazeSky.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item third-card">
                <a
                  className="grid__link"
                  href="https://www.bungie.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/Bungie_Logo_black_background.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item fourth-card">
                <a
                  className="grid__link"
                  href="https://dscryb.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/dscryb-logotype-final-white2.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item fifth-card">
                <a
                  className="grid__link"
                  href="https://www.funko.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/funko-logo.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item sixth-card">
                <a
                  className="grid__link"
                  href="https://www.hirezstudios.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/Hi-Rez-2021-RebrandedLOGO.jpg")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item seventh-card">
                <a
                  className="grid__link"
                  href="https://www.lvthn.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/Leviathan.jpg")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item eight-card">
                <a
                  className="grid__link"
                  href="https://www.monochromerpg.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/MonochromeWorkshop-768x255.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item ninth-card">
                <a
                  className="grid__link"
                  href="https://paizo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/Paizo Logo.jpg")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item tenth-card">
                <a
                  className="grid__link"
                  href="https://scopely.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/Scopely_logo_color.png")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item eleventh-card">
                <a
                  className="grid__link"
                  href="https://www.tactical-adventures.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/tactical adventures.jpg")
                        .default
                    }
                  />
                </a>
              </li>
              <li className="grid__item twelth-card">
                <a
                  className="grid__link"
                  href="https://company.wizards.com/en"
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="..."
                    className="grid__img layer"
                    src={
                      require("assets/img/logos/wizards logo 2.jpg")
                        .default
                    }
                  />
                </a>
              </li>
            </ul>
          </div>
        </section>

      </div>
    </>
  );
}

export default SectionCards;
