
/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import './single-blogpost.styles.css';

import blogPostCollection from '../../data/blog.data';
import IndividualPortfolioPageHeader from 'components/header/individual-portfolio-page-header';

// reactstrap components
import { Badge, Media, Container, Row, Col } from "reactstrap";

import {TwitterFollowButton} from 'react-twitter-embed';
import DOMPurify from 'dompurify';

const SingleBlogPost = (props) => {
    const [post, setPost] = useState({
        id: "",
        blogtitle: "",
        subtitle: "",
        blogcategory: "",
        postedon: "",
        author: "",
        headerblogimage: "",
        page1: "",
        page2: "",
        page3: "",
        page4: "",
        page5: "",
        page6: "",
    });

    const [postId, setPostId] = useState('');

    useEffect(() => {
        // console.log(blogPostCollection);
        const postId = props.match.params.postId;
        const post = blogPostCollection.find(post => post.id == postId);
        // console.log("post found" + post.blogtitle);
        setPost(post);
        setPostId(postId);
    }, [post, props.match.params.postId]);

    // if(post.bloImage == "") return null;

    return (
        <>
        <IndividualPortfolioPageHeader pageTitle={post.blogtitle} displayImage={post.headerblogimage} />
        <div className="wrapper">
        <div className="main">
          <div className=" section-white">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h2>{post.subtitle}</h2>
                  <h3 className="title-uppercase">
                    <small>Written by {post.author}</small>
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <div className="text-center">
                    <Badge className="main-tag" color="warning">
                      {post.blogcategory}
                    </Badge>
                    <h6 className="title-uppercase">{post.postedon}</h6>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="8">
                  
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.page1)}} />
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.page2)}} />
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.page3)}} />
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.page4)}} />
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.page5)}} />
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.page6)}} />
                    {/* <p>
                      {post.page1}
                    </p>
                    <br/>
                    <p>
                     {post.paragraph2}
                    </p>
                    <br/>
                    <p>
                     {post.paragraph3}
                    </p>
                    <br/>
                    <p>
                     {post.paragraph4}
                    </p>
                    <br/>
                    <p>
                      {post.paragraph5}
                    </p> */}
               
                  <br />
                  <div className="article-footer">
                    <Container>
                      <Row>
                        <Col md="6">
                          <h5>Category:</h5>
                          <Badge color="warning">{post.blogcategory}</Badge>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <hr />
                  <Container>
                    <Row>
                      <Media>
                        <a
                          className="pull-left"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="avatar big-avatar">
                            <Media
                              alt="..."
                              object
                              src={
                                require("assets/img/bioimages/m3ED_Zfo_400x400.jpg")
                                  .default
                              }
                            />
                          </div>
                        </a>
                        <Media body>
                          <Media heading>Matt Sernett</Media>
                          {/* <div className="pull-right">
                            <TwitterFollowButton screenName={'Sernett'} />
                          </div> */}
                          <p>
                          Thanks for reading and for checking out my site! Drop me a line using the contact form below.
                          </p>
                        </Media>
                      </Media>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
        </>
    )
};

export default SingleBlogPost;
