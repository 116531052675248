import AWARD_DATA from '../../data/awards.data';

const INITIAL_STATE = {
    collection: AWARD_DATA
};

// const INITIAL_STATE = {
//     collection: [
//         {
//             id: 1,
//             gamerelease: 'Mordenkainens Tome of Foes',
//             grantedby: 'Origins',
//             awardname: 'Best Roleplaying Game Supplement',
//             year: '2019'
//         },
//         {
//             id: 2,
//             gamerelease: 'Xanathars Guide to Everything',
//             grantedby: 'Golden Geek',
//             awardname: 'RPG Best Artwork and Presentation',
//             year: '2017'
//         },
//         {
//             id: 3,
//             gamerelease: 'Tales from the Yawning Portal',
//             grantedby: 'ENnies',
//             awardname: 'Judges Spotlight Winner',
//             year: '2017'
//         }
//     ]
// };


const awardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default awardReducer;