import React from "react";
import './header.styles.css';


function IndividualPortfolioPageHeader(props) {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{ backgroundImage: `url(${props.displayImage})` }}
        // style={{
        //   backgroundImage:
        //     "url(" +
        //     require("assets/img/dkjcwcxwh1825_DnD_Header.jpg").default +
        //     ")",
        // }}
      >
        <div className="filter" />
        <div className="content-center">
            <div className="motto">
              <h1 className="description headerClamp"><strong>{props.pageTitle}</strong></h1>
              <h3 className="description subHeaderClamp">{props.generalDescription}</h3>
            </div>
        </div>
      </div>
    </>
  );
}

export default IndividualPortfolioPageHeader;
