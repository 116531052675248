import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CollectionTablePreview from '../collection-portfolio-preview/collection-table-preview.component';

import { selectCollectionsForPreview } from '../../redux/portfolio/portfolio.selectors';

const CollectionTableOverview = ({ collections }) => (
  <div>
    {
        collections
        .map(({ id, ...otherCollectionProps }) => (
        <CollectionTablePreview key={id} {...otherCollectionProps} />
    ))}
  </div>
);

const mapStateToProps = createStructuredSelector({
  collections: selectCollectionsForPreview
});

export default connect(mapStateToProps)(CollectionTableOverview);