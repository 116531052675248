import React from 'react';
import SingleBlogPost from 'components/single-blogpost/single-blogpost.component';

const Post = (props) => {
    //console.log(props);
    return (
            <SingleBlogPost {...props}/>
    )
};

export default Post;