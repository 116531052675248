import React from "react";
import './header.styles.css';

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

import { Link } from 'react-scroll';

function LandingPageHeader() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        className="page-header-center"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/img/homepage/destiny2shadowkeep.jpg").default +
            ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="description headerClamp"><strong>Matt Sernett</strong></h1>
              <h3 className="description sevicesClamp">Creative Director • Narrative Designer • Story Consultant • World Builder</h3>
              <h3 className="description subHeaderClamp">
              I’ve worked as a writer and game designer since 2000. During that time, I’ve built worlds and written for AAA titles, mobile games, table-top RPGs, card games, miniatures games, board games, and digital classics like <em>Baldur’s Gate</em>.
             </h3>
              <br />
              <Link to="services_anchor" smooth={true}>
              <Button
                  className="btn-magnify btn-round mr-1"
                  color="neutral"
                >
                 Explore Below <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
