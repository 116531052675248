import React from "react";

// reactstrap components
import LandingPageHeader from "components/header/landing-page-header.component";

import SectionServices from "./sections/section-services.components";
import SectionCards from "./sections/section-cards.component";
import SectionTestimonials from "./sections/section-testimonials.component";

function LandingPage() {
    const checkScroll = () => {
        // it takes all the elements that have the .add-animation class on them
        const componentPosition = document.getElementsByClassName("add-animation");
        const scrollPosition = window.pageYOffset;
        for (var i = 0; i < componentPosition.length; i++) {
          var rec =
            componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
          // when the element with the .add-animation is in the scroll view,
          // the .animated class gets added to it, so it creates a nice fade in animation
          if (scrollPosition + window.innerHeight >= rec) {
            componentPosition[i].classList.add("animated");
            // when the element with the .add-animation is not in the scroll view,
            // the .animated class gets removed from it, so it creates a nice fade out animation
          } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
            componentPosition[i].classList.remove("animated");
          }
        }
      };
    
      React.useEffect(() => {
        document.body.classList.add("presentation-page");
        window.addEventListener("scroll", checkScroll);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("presentation-page");
          window.removeEventListener("scroll", checkScroll);
        };
      });

    return (
        <>
            <LandingPageHeader />   
            <SectionServices />
            <SectionCards />
            <SectionTestimonials />
        </>
    );
}

export default LandingPage;