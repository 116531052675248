import React from "react";
import { Link as Link1 } from 'react-router-dom';

import { Link as Link2 } from 'react-scroll';
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";
// core components

function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link1}>
              MATT SERNETT
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
            <DropdownToggle className="mr-2" color="default" nav>
                  Portfolio
            </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem to="/portfolio/digitalgames" tag={Link1}>
                    Digital Games
                  </DropdownItem>
                  <DropdownItem to="/portfolio/tabletopgames" tag={Link1}>
                    Table Top Games
                  </DropdownItem>
                  <DropdownItem to="/portfolio/articles" tag={Link1}>
                    Articles
                  </DropdownItem>
                  <DropdownItem to="/portfolio/other" tag={Link1}>
                    Other Writing
                  </DropdownItem>
                  <DropdownItem to="/portfolio/media" tag={Link1}>
                    Media Appearances
                  </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink to="/services" tag={Link1}>Services</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about" tag={Link1}>About Me</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/awards" tag={Link1}>Awards</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/blog" tag={Link1}>Blog</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="contact_anchor" smooth={true} tag={Link2} style={{cursor:'pointer'}}>Contact</NavLink>
              {/* <NavLink to="contact_anchor"><Link2 className="link-pointer" to="contact_anchor" smooth={true}>Contact</Link2></NavLink> */}
            </NavItem>
              <a href="https://www.linkedin.com/in/matt-sernett/" rel="noopener noreferrer" target="_blank">
                  <Button className="btn-just-icon btn-border mr-1" color="linkedin">
                      <i className="fa fa-linkedin" />
                  </Button>
              </a>
            </Nav> 
          </Collapse>  
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;
