import PORTFOLIO_DATA from "../../data/portfolio.data";

const INITIAL_STATE = {
    collections: PORTFOLIO_DATA
};

const portfolioReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        default:
            return state;
    }
};

export default portfolioReducer;