import React from "react";
import { Link } from 'react-scroll';
import './header.styles.css';

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
const items = [
    {
      src:
        "url(" +
        require("assets/img/services/Header.png").default +
        ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="description headerClamp" style={{paddingBottom:'10px'}}><strong>Game Writing & Narrative Design</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Link to="gwd_anchor" smooth={true}>
                <Button
                    className="btn-magnify btn-round mr-1"
                    color="neutral"
                  >
                  Read More <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </Col>
           </Row>
        </Container>
      ),
      altText: "game writing and narrative design",
      caption: "game writing and narrative design",
    },
    {
      src:
        "url(" +
        require("assets/img/services/WorldBuilding.jpg").default +
        ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="description headerClamp" style={{paddingBottom:'10px'}}><strong>World Building</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Link to="wb_anchor" smooth={true}>
                <Button
                    className="btn-magnify btn-round mr-1"
                    color="neutral"
                  >
                  Read More <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </Col>
           </Row>
        </Container>
      ),
      altText: "world building",
      caption: "world building",
    },
    {
      src:
        "url(" +
        require("assets/img/services/DialogueandVODirection.png").default +
        ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="description headerClamp" style={{paddingBottom:'10px'}}><strong>Dialogue & VO Direction</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Link to="dvd_anchor" smooth={true}>
                <Button
                    className="btn-magnify btn-round mr-1"
                    color="neutral"
                  >
                  Read More <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </Col>
           </Row>
        </Container>
      ),
      altText: "Dialogue & VO Direction",
      caption: "Dialogue & VO Direction",
    },
    {
      src:
        "url(" +
        require("assets/img/services/unnamed.jpg").default +
        ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="description headerClamp" style={{paddingBottom:'10px'}}><strong>Story Consultation</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Link to="sc_anchor" smooth={true}>
                <Button
                    className="btn-magnify btn-round mr-1"
                    color="neutral"
                  >
                  Read More <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </Col>
           </Row>
        </Container>
      ),
      altText: "Story Consultation",
      caption: "Story Consultation",
    },
    {
      src:
        "url(" +
        require("assets/img/services/ScriptEditingDoctoring.png").default +
        ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="description headerClamp" style={{paddingBottom:'10px'}}><strong>Script Editing & Doctoring</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Link to="sed_anchor" smooth={true}>
                <Button
                    className="btn-magnify btn-round mr-1"
                    color="neutral"
                  >
                  Read More <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </Col>
           </Row>
        </Container>
      ),
      altText: "Script Editing & Doctoring",
      caption: "Script Editing & Doctoring",
    },
    {
      src:
        "url(" +
        require("assets/img/services/BoardGameTechnicalWriting.png").default +
        ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="description headerClamp" style={{paddingBottom:'10px'}}><strong>Board Game Technical Writing</strong></h1>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <Link to="bgtw_anchor" smooth={true}>
                <Button
                    className="btn-magnify btn-round mr-1"
                    color="neutral"
                  >
                  Read More <i className="nc-icon nc-minimal-down mr-1" />
                </Button>
              </Link>
            </Col>
           </Row>
        </Container>
      ),
      altText: "Board Game Technical Writing",
      caption: "Board Game Technical Writing",
    },
  ];

  function ServicesHeader() {
    // carousel - header 3
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
    setAnimating(true);
    };
    const onExited = () => {
    setAnimating(false);
    };
    const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    };
    const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
    };
  
    return (
    <>
     <div className="header-3">
          <div className="page-carousel">
            <div className="filter" />
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.src}
                  >
                    <div
                      className="page-header"
                      style={{ backgroundImage: item.src }}
                    >
                      <div className="filter" />
                      <div className="content-center">{item.content}</div>
                    </div>
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>
    </>
    )
  };

  export default ServicesHeader;