import React from "react";
import './header.styles.css';

// reactstrap components
import { Container } from 'reactstrap';

// core components

function IndividualPortfolioPageHeader(props) {
  return (
    <>
      <div
        className="page-header-center page-header-small"
        style={{ backgroundImage: `url(${props.displayImage})` }}
      >
        <div className="filter" />
        <div className="content-center">
        <Container>
            <div className="motto">
              <h1 className="description headerClamp" style={{color:'white'}}><strong>{props.pageTitle}</strong></h1>
              <h3 className="description subHeaderClamp">{props.generalDescription}</h3>
            </div>
        </Container>   
        </div>
      </div>
    </>
  );
}

export default IndividualPortfolioPageHeader;
