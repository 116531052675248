import React from 'react';

import IndividualBioPageHeader from '../../components/header/individual-bio-page-header';

// reactstrap components
import { Card, CardBody, CardText, Container, Row, Col } from "reactstrap";

function BioPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("blog-post");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      return function cleanup() {
        document.body.classList.remove("blog-post");
      };
    });
return (
    <>
    <IndividualBioPageHeader pageTitle="About Me" displayImage="/images/aboutme/IMG_79212y.jpg"/>
        {/* <div className="wrapper">
            <div className="main"> */}
                <div className="section section-white">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">
                                <h2 style={{marginBottom:'20px'}}>My name is Matt Sernett, and I’ve written for a game you love. </h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                
                            <div className="article-content">
                                <p>
                                If you’ve got D&D books on your shelves, Magic cards in a binder, or a closet full of board games and miniatures, I’m almost certain that you already own some of my writing. But besides table-top games, I’ve also contributed to <em>Destiny 2</em>, <em>Rogue Company</em>, <em>Solasta: Crown of the Magister</em>, and a bunch of other games that I can’t talk about just yet.
                                </p>
                                <br/>
                                <p>
                                I launched on this incredible journey a long time ago. 
                                </p>
                                <br/>
                             
                            <Row>
                            <Col md="6">
                                        <Card className="card-plain"
                                            style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/bioimages/Conan.jpg")
                                                .default +
                                                ")",
                                            }}
                                            />
                                    </Col>
                            <Col className="mr-auto" md="6">
                                        <Card className="card-plain text-left">
                                            <CardBody>
                                            <CardText>
                                                <p>
                                                    When I was 8 years old, I stood in the crypt of a cathedral peering over the tops of tables at a church book sale. A splash of red and a pair of gleaming eyes caught my attention. The lurid colors and visceral composition of Frank Frazetta had stopped me in my tracks. And so I dragged a dogeared 1967 copy of <em>Conan</em> off the table and asked my parents if I could have it. They thought it was perhaps a bit too mature for me but relented.
                                                </p>
                                                <br/>
                                                <p>
                                                    And that’s how I ended up in footie pajamas in my parents’ bedroom one night asking the question, “Daddy, what’s a ‘skim-a-tar?’”
                                                </p>
                                            </CardText>
                                            </CardBody>
                                        </Card>
                                     </Col> 
                                   
                            </Row>
                                <p />
                              
                                <br/>
                                <p>
                                Neither of my parents knew what I was talking about, so they sent me to the dictionary. Perhaps learning on my own is what cemented that memory in my mind. It’s probably a big part of why I found R. A. Salvatore’s scimitar-wielding Drizzt Do’Urden so compelling a few years later. By then I’d been playing D&D with my friends like the characters in <em>E.T.</em> for several years, and we’d branched out to other RPGs and war games. 
                                </p>
                                <br/>
                              
                                <Row>
                                     <Col className="mr-auto" md="6">
                                        <Card className="card-plain text-left">
                                            <CardBody>
                                            <CardText>
                                            <p>
                                                Yet R. A. Salvatore’s <em>The Crystal Shard</em> was the first novel I’d read where one author was telling just part of the world’s story. It was my first experience with a shared world. I devoured everything I could find, becoming an avid—let’s face it, obsessive—collector of the Forgotten Realms, and then all the other settings of D&D. As I encountered new worlds and authors that fascinated me, my collection grew: Warhammer and Warhammer 40K, of course, but also the works of Asimov, Bradbury, Heinlein, Pratchett, and on and on.
                                            </p>
                                            <br/>
                                            <p>
                                            I have walked on too many worlds and lived the lives of too many people to remember them all.
                                            </p>
                                            </CardText>
                                            </CardBody>
                                        </Card>
                                     </Col> 
                                    <Col md="6">
                                        <Card className="card-plain"
                                            style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/bioimages/crystalshard.jpg")
                                                .default +
                                                ")",
                                            }}
                                            />
                                    </Col>
                                </Row>
                                
                                
                               
                                <br/>
                                <p>
                                It was probably during the same summer that I read <em>The Crystal Shard</em> that I experienced a transformative revelation. I had spent the night at a friend’s house, and as we looked at a roleplaying book, we realized—seemingly for the first time—that the book had credits and an author. It meant someone got paid to make it. It was that person’s job to make roleplaying games!
                                </p>
                                <br/>
                                <p>
                                As we expressed our wonderment and fantasized about growing up to be like that person, my friend’s father walked by the room and scolded him, “You’re never going to do that. Put that out of your head.” My crestfallen friend said, “Yes, dad.” But I found the whole thing so strange, it made me think about it more seriously. Why couldn’t I grow up to write for RPGs? What was stopping me?
                                </p>
                                <br/>
                                <p>
                                From that point on, I had a guiding star, and I pointed my rocket toward it. When I attended college, there were no programs or classes for game design, so I forged my own path through magazine journalism, eventually securing a job with <em>Dragon</em>, the D&D magazine that liberally littered the bedrooms of me and my friends as we grew up. That led in turn to work as a D&D designer, a job I did long enough to turn my heroes into peers. 
                                </p>
                                <br/>
                                <p>
                                I’ve listened to Ed Greenwood, the creator of the Forgotten Realms, tell tales of dubious tallness so often that I can count their tree rings. Not only do I know Boston-bred R. A. Salvatore well enough to call him Bob, but I count him as a friend. And I’ve had the enormous pleasure of working for over a decade alongside Kim Mohan, a quiet titan of the RPG industry who stood watch over its ever-expanding borders since Gary Gygax hired him to work in the tiny white house that served as the TSR periodicals office in Lake Geneva in 1979.
                                </p>
                                <br/>
                                <p>
                                As a kid I watched the Dungeons & Dragons cartoon on TV, and as an adult, I wrote the book that accompanied its DVD collection.
                                </p>
                                  
                            <Row>
                            <Col md="6">
                                        <Card className="card-plain"
                                            style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/bioimages/DnDcartoonbook1.jpg")
                                                .default +
                                                ")",
                                            }}
                                            />
                                    </Col>
                                    <Col md="6">
                                        <Card className="card-plain"
                                            style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/bioimages/DnDcartoonbook2.jpg")
                                                .default +
                                                ")",
                                            }}
                                            />
                                    </Col>
                                   
                            </Row>
                                <br/>
                                <p>
                                I went on a wild adventure with D&D, with a ton of fantastic side quests along the way, but I’ve always had my eyes set on far horizons.
                                </p>
                                <br/>
                                <p>
                                Just as I’d continually collected and played other table-top games, I was playing every digital game that came my way. I remember playing Pong on the first Atari console, the dorky sound effects of the ColecoVision, and using DOS and half a dozen floppy discs just to play one game—the 5.25-inch discs that were actually floppy!  
                                </p>
                                <br/>
                                <p>
                                So, I left one dream job for another, and now I write for digital games, building worlds, devising stories, and breathing life into the people and the creatures that inhabit them.  
                                </p>
                                <br/>
                                <p>
                                I’ve never stopped making up stories and playing pretend. I never will. When I die, if you check my hard drive—or cloud storage or neural net or whatever the next thing is—you’ll find at least a half dozen half-finished fictions, with the last-saved timestamp probably just a few hours prior to my passing. 
                                </p>
                                <br/>
                                <p>
                                Because there are still stars out there, and I’ve got a rocket to ride.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
    {/* </div>
</div> */}
</>
    )
};

export default BioPage;

