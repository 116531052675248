const CURRENT_DATA = 
[
    {
        "id": 1,
        "type": "play",
        "title": "Manor Lords",
        "url": "https://manorlords.com",
    },
    {
        "id": 2,
        "type": "play",
        "title": "Baldur's Gate 3",
        "url": "https://baldursgate3.game",
    },
    {
        "id": 3,
        "type": "play",
        "title": "Weird West",
        "url": "https://www.devolverdigital.com/games/weird-west",
    },
    {
        "id": 4,
        "type": "play",
        "title": "Cyberpunk 2077",
        "url": "https://www.cyberpunk.net/us/en/",
    },
    {
        "id": 5,
        "type": "play",
        "title": "Legend of Grimrock",
        "url": "https://www.grimrock.net",
    },
    {
        "id": 6,
        "type": "read",
        "title": "The Last Battle",
        "url": "https://www.dacapopress.com/titles/stephen-harding/the-last-battle/9780306822964/",
    },
    {
        "id": 7,
        "type": "read",
        "title": "Herding Tigers",
        "url": "https://www.toddhenry.com/herdingtigers/",
    },
    {
        "id": 8,
        "type": "read",
        "title": "Chief Seattle and the Town that Took His Name",
        "url": "https://www.penguinrandomhouse.com/books/554206/chief-seattle-and-the-town-that-took-his-name-by-david-m-buerge/",
    },
    {
        "id": 9,
        "type": "read",
        "title": "Hercules: The First Superhero",
        "url": "http://www.monasheemountainpublishing.com/index.html",
    },
    {
        "id": 10,
        "type": "read",
        "title": "The Street of Crocodiles",
        "url": "https://www.penguinrandomhouse.com/books/293938/the-street-of-crocodiles-and-other-stories-by-bruno-schulz-foreword-by-jonathan-safran-foer-introduction-by-david-a-goldfarb-translated-by-celina-wieniewska/9780143105145/readers-guide",
    },
];

export default CURRENT_DATA;
