import React from "react";

import { connect } from 'react-redux';

import { selectBlogCollection } from "redux/blog/blog.selectors";
import { createStructuredSelector } from "reselect";
import { NavLink } from 'react-router-dom';

import Sidebar from '../../components/sidebar/sidebar.component';
import IndividualPortfolioPageHeader from "components/header/individual-portfolio-page-header";
import './blog-page.styles.css';
// reactstrap components
import {
  Button,
  Row,
  Col,
  Container,
} from "reactstrap";

import BlogpostItem from "components/blogpost-item/blogpost-item.component";

const BlogPosts = ({ blogCollection }) => {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("blog-posts");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
      return function cleanup() {
        document.body.classList.remove("blog-posts");
      };
    });
    // console.log(blogCollection);
    return (
      <>
      <IndividualPortfolioPageHeader 
        pageTitle="Blog" 
        displayImage="/images/blog/header.png" 
        generalDescription="If I’ve got something substantive to say, it’ll end up here." 
      />

        <div>
          <div className="main">
            <div className=" section-white">
            <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center title" md="10">
                    <h2>Recent Posts</h2>
                  </Col>
                </Row>
                <div className="article">
                  <Row>
                      <Col className="ml-auto mr-auto" md="6">
                        {blogCollection
                          .slice(0)
                          .reverse()
                          .filter(item => item.featured === "y")
                          .map(({id, ...otherBlogItemProps}) => (
                          <div>
                            <BlogpostItem key={id} {...otherBlogItemProps} />
                            <div style={{textAlign:'center'}}>
                              <NavLink key={id} to={`/posts/${id}`}>
                                  <Button className="btn-round" color="danger" size="sm">
                                    Read more
                                  </Button>
                              </NavLink>
                            </div>
                            <br />
                            <br />
                          </div>
                        ))}  
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                      <Sidebar />
                      </Col>
                  </Row>
                </div>
                
                </Container>
            </div> 
          </div>
              
        </div>
      </>
    );
  };
  
  const mapStateToProps = createStructuredSelector({
    blogCollection: selectBlogCollection
  });

  export default connect(mapStateToProps)(BlogPosts);
