import { createSelector } from "reselect";

const selectPortfolio = state => state.portfolio;

export const selectCollections = createSelector(
    [selectPortfolio],
    portfolio => portfolio.collections
);

export const selectCollectionsForPreview = createSelector(
    [selectCollections],
    collections => Object.keys(collections).map(key => collections[key])
);

export const selectCollection = collectionUrlParam =>
createSelector(
    [selectCollections],
    collections => collections[collectionUrlParam]
);


