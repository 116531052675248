import React from 'react';
import { connect } from 'react-redux';

import { selectCollection } from '../../../redux/portfolio/portfolio.selectors';
import CollectionPortfolioItem from '../../../components/collection-portfolio-item/collection-portfolio-item.component';
import IndividualPortfolioPageHeader from '../../../components/header/individual-portfolio-page-header';

import CollectionTableItem from 'components/collection-portfolio-item/collection-table-item.component';

import {
  Row,
  Container,
  Col,
  Table
} from 'reactstrap';

const CollectionPage = ({collection}) => {
  const { category, displayImage, generalDescription, portfolioitems } = collection;

  return(
    <>
    <IndividualPortfolioPageHeader pageTitle={category} displayImage={displayImage} generalDescription={generalDescription} />
      <div className="section-dark">
       <Container>
         {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <div className="space-top" /> */}
                {/* <h6 className="category">{generalDescription}</h6> */}
                {/* <h2 className="title">{category}</h2> */}
              {/* </Col>
        </Row> */}
        <div>
          <Row>
        
              {portfolioitems
              //.filter((item, idx) => idx < 3)
              .filter(spot => spot.spotlight.includes('y'))
              .map(portfolioitem => (
                  <CollectionPortfolioItem key={portfolioitem.id} portfolioitem={portfolioitem} />
              ))}
             
          </Row>
        </div>
       
        </Container>
      </div>
      
      <div className="section-gray">
      <Container>
          <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Table className="table-shopping" responsive striped size="sm">
                <thead>
                  <tr>
                    <th className="text-left"><strong>Title</strong></th>
                    <th className="text-left"><strong>Publisher</strong></th>
                    <th className="text-left"><strong>Contribution</strong></th>
                  </tr>
                </thead>
                <tbody>
                {/* .filter(spot => spot.spotlight.includes('n')) */}
                {portfolioitems
                .slice(0)
                .reverse()
                .map(portfolioitem => (
                    <CollectionTableItem key={portfolioitem.id} portfolioitem={portfolioitem} />
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
      </Container>
        </div>
      </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    collection: selectCollection(ownProps.match.params.collectionId)(state)
});

export default connect(mapStateToProps)(CollectionPage);