/* eslint eqeqeq: 0 */
import React from 'react';
import './sidebar.styles.css';

import SidebarCard from './sidebar-card.component';

//import blogPostCollection from '../../data/blog.data';
//import currentCollection from '../../data/current.data';

import { createStructuredSelector } from 'reselect';

import { selectBlogCollection } from 'redux/blog/blog.selectors';
import { selectCurrentCollection } from 'redux/current/current.selectors';
import { connect } from 'react-redux';

import PaginationComponent from 'components/pagination/pagination-component';

const Sidebar = ({blogItems, currentItems}) => {
    // console.log(blogItems);
    // console.log(currentItems);
    //const [posts, setPosts] = useState([]);
    //const [current, setCurrent] = useState([]);

    //useEffect(() => {
        //const posts = blogPostCollection;
        //const current = currentCollection;
        //setPosts(posts);
        //setCurrent(current);
    //}, [current]);

    //console.log(current);

    return (
        <div className="sidebarContainer">

            <SidebarCard style={{marginBottom:'20px', boxSizing:'border-box'}}> 
                <div className="sidebarCardHeader">
                    <span>Currently Playing</span>
                </div>
                {
                    currentItems.filter(play => play.type == 'play').map(filteredPlaying => (
                        <div>
                            <div className="sidebarCardTitle"><a href={filteredPlaying.url} target="_blank" rel="noopener noreferrer" style={{color:'#000'}}>{filteredPlaying.title}</a></div>
                            {/* <div className="profileImageContainer">
                                <a href={filteredPlaying.url} target="_blank" rel="noopener noreferrer">
                                    <img src={filteredPlaying.image} alt=""/>
                                </a>
                            </div>
                            <div className="cardBody">
                                <p className="personalBio">{filteredPlaying.description}</p>
                            </div> */}
                        </div>
                    ))
                }
            </SidebarCard>
            {/* <hr /> */}
            <SidebarCard style={{marginBottom:'20px', padding:'20px', boxSizing:'border-box'}}> 
                <div className="sidebarCardHeader">
                    <span>Currently Reading</span>
                </div>
                {
                    currentItems.filter(read => read.type == 'read').map(filteredReading => (
                        <div>
                            <div className="sidebarCardTitle"><a href={filteredReading.url} target="_blank" rel="noopener noreferrer" style={{color:'#000'}}>{filteredReading.title}</a></div>
                            {/* <div className="profileImageContainer">
                                <a href={filteredReading.url} target="_blank" rel="noopener noreferrer">
                                    <img src={filteredReading.image} alt=""/>
                                </a>
                            </div>
                            <div className="cardBody">
                                <p className="personalBio">{filteredReading.description}</p>
                            </div> */}
                        </div>
                    ))
                }
            </SidebarCard>
            {/* <hr /> */}
            <SidebarCard style={{marginBottom:'20px', padding:'20px', boxSizing:'border-box'}}>
                <div className="sidebarCardHeader">
                    <span>Older Posts</span>
                </div>
                {/* <div className="recentPosts">
                {blogItems
                   .filter((item, idx) => idx < 5)
                   .map(post => {
                       return (
                        <NavLink key={post.id} to={`/posts/${post.id}`}>
                            <div className="recentPost">
                                <h3>{post.blogtitle}</h3>
                                <span>{post.postedon}</span>
                            </div>
                        </NavLink>
                       );
                   })
                } */}
                {/* {
                   posts
                   .filter((item, idx) => idx < 5)
                   .sort()
                   .reverse()
                   .map(post => {
                       return (
                        <NavLink key={post.id} to={`/posts/${post.id}`}>
                            <div className="recentPost">
                                <h3>{post.blogtitle}</h3>
                                <span>{post.postedon}</span>
                            </div>
                        </NavLink>
                       );
                   })
                } */}
                {/* </div> */}
                <PaginationComponent />
            </SidebarCard>
                
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    blogItems: selectBlogCollection,
    currentItems: selectCurrentCollection
});

export default connect(mapStateToProps)(Sidebar);