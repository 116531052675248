import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ColorNavbar from 'components/navbar/navbar.component';
import LandingPage from 'pages/landing-page/landing-page.component';
import PortfolioPage from 'pages/portfolio/portfolio-page.component';
import ServicesPage from 'pages/services/services.component';
import AwardPage from 'pages/awards/award-page.component';
import BioPage from 'pages/bio/bio-page.component';
import BlogPosts from 'pages/blog/blog-page.component';
import Post from 'pages/post/post-page.component';

import ContactForm from 'components/contact/contact.component';
import FooterWhite from 'components/footer/footer.component';

class App extends React.Component {
  render() {
    return (
      <div>
       <ColorNavbar />
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path='/portfolio' component={PortfolioPage} />
          <Route path='/services' component={ServicesPage} />
          <Route path='/awards' component={AwardPage} />
          <Route path='/about' component={BioPage} />
          <Route path='/blog' component={BlogPosts} />
          <Route path="/posts/:postId" component={Post} />
        </Switch>
        <hr />
       <ContactForm />
       <FooterWhite />
      </div>
    );
  }
}

export default App;
