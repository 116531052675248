import React from 'react';


function CollectionTableItem ({ portfolioitem }) {
    const { title, contribution, publisher } = portfolioitem;

return (
        <>
        <tr>
        {/* <td>
             <div className="img-container">
               <img
                 alt="..."
                 src={imageUrl}
               />
             </div>
           </td> */}
           <td className="td-product">
             <strong>{title}</strong>
             {/* <p>
              {description}
             </p> */}
           </td>
           <td className="td-product">
             <small>{publisher}</small>
           </td>
           <td className="td-product">
             <small>{contribution}</small>
           </td>
           </tr>
        </>
    );
}

export default CollectionTableItem;