/*eslint-disable*/
import React from "react";
import { Link as Link1 } from 'react-router-dom';
// reactstrap components
import { Container, Row, NavItem, NavLink } from "reactstrap";

// core components

function FooterWhite() {
  return (
    <>
      <footer className="footer footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="/"
                    target="_blank"
                    className="mr-1"
                    tag={Link1}
                  >
                    Matt Sernett
                  </a>
                </li>
                <li>
                <NavItem>
                  <NavLink to="/portfolio/digitalgames" tag={Link1}>Portfolio</NavLink>
                </NavItem>
                  {/* <a
                    href="/portfolio/digitalgames"
                    target="_blank"
                    className="mr-1"
                    tag={Link1}
                  >
                    Portfolio
                  </a> */}
                </li>
                <li>
                <NavItem>
                  <NavLink to="/services" tag={Link1}>Services</NavLink>
                </NavItem>
                  {/* <a
                    href="/services"
                    target="_blank"
                    className="mr-1"
                    tag={Link1}
                  >
                    Services
                  </a> */}
                </li>
                <li>
                <NavItem>
                  <NavLink to="/about" tag={Link1}>About Me</NavLink>
                </NavItem>
                  {/* <a
                    href="/bio"
                    target="_blank"
                    className="mr-1"
                    tag={Link1}
                  >
                    Bio
                  </a> */}
                </li>
                <li>
                <NavItem>
                  <NavLink to="/awards" tag={Link1}>Awards</NavLink>
                </NavItem>
                  {/* <a
                    href="/awards"
                    target="_blank"
                    tag={Link1}
                  >
                    Awards
                  </a> */}
                </li>
                <li>
                <NavItem>
                  <NavLink to="/blog" tag={Link1}>Blog</NavLink>
                </NavItem>
                  {/* <a
                    href="/blog"
                    target="_blank"
                    className="mr-1"
                    tag={Link1}
                  >
                    Blog
                  </a> */}
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright" style={{color:"#777777", fontWeight:"400"}}>
                © {new Date().getFullYear()}
                , Matt Sernett | Developed by <a href="https://www.davidschlegel.com" target="_blank" rel="noopener noreferrer" style={{color:"#777777", fontWeight:"400"}}>DS</a>
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterWhite;
