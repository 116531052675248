import React from 'react';
import { Route } from 'react-router-dom';

//import PORT_DATA from './port.data';
import CollectionPage from './collection/collection.component';
import CollectionTableOverview from "../../components/collection-portfolio-overview/collection-table-overview";

const PortfolioPage = ({ match }) => {
    return (
     <div>
        <Route exact path={`${match.path}`} component={CollectionTableOverview} />
        <Route path={`${match.path}/:collectionId`} component={CollectionPage} />
    </div>
)};

export default PortfolioPage;