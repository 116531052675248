import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import {
    Button,
    Form,
    Input,
    Container,
    Row,
    Col,
    Alert,
  } from "reactstrap";

const ContactForm = (props) => {
    const[visible, setVisible] = useState(false);
    const onDismiss = () => setVisible(false);
    
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_wxdgide', 'template_mlkhk2l', e.target, 'user_yXulb9AMUTldv9M2NIULL')
          .then((result) => {
              setVisible(true);
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
        }

    return (
    <>
        <div id="contact_anchor" className="section landing-section">
            <Container>
                <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <h2 className="text-center">Get in touch!</h2>
                    <Form className="contact-form" onSubmit={sendEmail}>
                    <Row>
                        <Col md="6">
                        <label>Name</label>
                        <Input placeholder="Name" name="from_name" required/>
                        </Col>
                        <Col md="6">
                        <label>Email</label>
                        <Input placeholder="Email" name="user_email" required type="email" />
                        </Col>
                    </Row>
                    <label>Message</label>
                    <Input
                        placeholder="Thoughts..."
                        type="textarea"
                        rows="4"
                        name="message"
                    />
                    <Row>
                        <Col className="offset-md-4" md="4">
                        <Button className="btn-fill" color="danger" size="lg" type="submit" value="Send">
                            Send Message
                        </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert color="success" isOpen={visible} toggle={onDismiss}>
                                Your message was successfully sent! I will get back to you as soon as possible.
                            </Alert>
                        </Col>
                    </Row>
                    </Form>
                </Col>
                </Row>
            </Container>
            </div>
        </>
    );
};

export default ContactForm;