import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/Richard.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Working with Matt was a master class in narrative design."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Richard Maddern</CardTitle>
            <h6 className=" card-category">Playgig, TLM</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "3",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/Swen_Vincke.png"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "I had the pleasure of brainstorming with Matt and found him to be a very talented, creative and experienced designer with a good sense of humor. He was quick to grasp the ideas behind something and his contributions enriched the discussion. Even when things went completely crazy, he impressed by his ability to maintain the overview and come up with elegant solutions."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Swen Vincke</CardTitle>
            <h6 className=" card-category">Larian</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/JohnS.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt has a keen sense of the big picture, all the little details, and the connections and consequences between them. From IP development to stories and settings and characters, he’s always thinking of how it all pieces together. Arguably most important though is his consideration for the end user’s experience and how they will encounter, process, and affiliate with the work to become fans and join us in the worlds we create. Hire him now."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">John Sahas</CardTitle>
            <h6 className=" card-category">Playgig, Infinity Ward, Neversoft</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/NikolausDavidson.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt is one of the more talented writers in the game industry, able to take a game concept and really turn it into a living world."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Nikolaus Davidson</CardTitle>
            <h6 className=" card-category">One More Game, Pokemon Company, Wizards of the Coast</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "3",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/ElisaMader.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "He immediately impressed me with his lucid writing style, his instinct for character voice and natural dialogue, and his rapid mastery of in-game lore. He always brought his extensive experience in games to bear productively in writers’ room discussions, as well as his passion for developing strong, emotionally resonant story lines."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Elisa Mader</CardTitle>
            <h6 className=" card-category">Wooga, Bungie, Privateer Press</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "7",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/ChrisSims.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "I've known and worked with Matt for years, and he's a true pro. He's a skilled creative, great at writing, at all aspects of worldbuilding, and at seeing the big picture when it comes to developing and maintaining intellectual properties, new or historied. At Playgig, Matt was also the creative director, and his leadership was innovative, insightful, and inspirational. The world he came up with to underpin the company's first game and future endeavors was chef's-kiss good. That and his vision made creating and telling more stories in that fictional universe not only compelling, but also so much easier, as clear visions do."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Chris Sims</CardTitle>
            <h6 className=" card-category">Playgig, Wizards of the Coast, Paizo</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "7",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/IanLowson.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Incredibly creative, Matt has an excellent work ethic. He's able to quickly produce first-class material, and then refine and fashion it into a cut gem that exactly suits the needs of the client. He is an excellent communicator of ideas, finding the best way to put across an idea. During brainstorming sessions, he doesn't try to dominate the chat, giving his colleagues space to propose their own ideas and then helping to bring it all together into something everyone feels part of. That is a rare skill—one of many Matt possesses."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Ian Lowson</CardTitle>
            <h6 className=" card-category">ChangYou, Studio Gobo, Midway</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "2",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/BrandonBozi.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "I have great admiration for his ability to create deep, compelling, enjoyable game worlds. Matt's ability to both craft the broad landscape of a story and fine tune its details would be a huge asset to any game company."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Brandon Bozi</CardTitle>
            <h6 className=" card-category">Rec Room, Big Fish, Wizards of the Coast</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "2",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/JesseDecker.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt is that rare breed of professional that combines a creative and imaginative mind with exceptional organizational skills, keen attention to detail, and a strict adherence to deadlines. Matt is an exceptional editor and writer, and there is no one with whom I’d rather collaborate on a project."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Jesse Decker</CardTitle>
            <h6 className=" card-category">Pocket Gems, Gazillion, Wizards of the Coast</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/Dan.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "I've never worked with a more skilled loremaster, world-builder, narrative designer, or IP manager. Matt has a wonderful talent for keeping an eye on the long view when it comes to crafting an original IP while still fostering excitement for what's going on in the short-term."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Dan Helmick</CardTitle>
            <h6 className=" card-category">Playgig, Wizards of the Coast, Forrest-Pruzan</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "3",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/DavidNoonan.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "The thing that impresses me about Matt is that he relishes the collaborative process. When the designers gather to tackle a thorny problem or brainstorm new ideas, Matt is very much in his element. He's always “productively creative,” able to contribute not only when the muse strikes, but on any given Tuesday morning when deadlines are looming, and we need good ideas ASAP."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">David Noonan</CardTitle>
            <h6 className=" card-category">Bluehole, Wildcard, Monolith</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "4",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/Gabriel.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt is an awesome pro who's got an amazing mix of creative skills and leadership abilities. His ideas were not only imaginative but also grounded in a deep understanding of gameplay and storytelling. Beyond his professional abilities, he's a super positive individual, always staying calm and finding solutions to doubts and insecurity around the team."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Gabriel Franchini</CardTitle>
            <h6 className=" card-category">Playgig, Insane, Pipa Studios</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "7",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/ChrisPerkins.jpg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "I am blown away by Matt’s pursuit of excellence. He approaches every project with a clear vision and doesn’t shy away from asking important questions about a project’s strategic role and objectives. He is approachable and always places the needs of the project first, and he doesn’t let ego stand in the way of a good idea."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Chris Perkins</CardTitle>
            <h6 className=" card-category">Wizards of the Coast</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "5",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/PedroAndresSanchez.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt was fantastic to brainstorm with—the energy, enthusiasm, and creativity he brought to sessions always led to great ideas for the group. In addition, his writing was precisely on brief, and the client loved it. I hope to collaborate with him again in the future. "
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Pedro Sanchez</CardTitle>
            <h6 className=" card-category">Leviathan, Monster Media</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "6",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/DrewStewart.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt was able to onboard to our project quickly, approaches every meeting and assignment with a high degree of professionalism and enthusiasm, was able to provide content that helped build out the world of our game while minding the extensive legacy IP constraints, and is just a lovely person to interact with besides. I would consider it a privilege to work with Matt in the future."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Drew Stewart</CardTitle>
            <h6 className=" card-category">Omnidrone, King, Jam City, Storm8</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "8",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/AdamMiller.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt’s ability to step in, research quickly, and follow through with shippable content in a short amount of time is essential to a game narrative team. I'd welcome working with Matt again and would recommend him for future narrative writing and design positions. "
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Adam Miller</CardTitle>
            <h6 className=" card-category">Bungie, Telltale Games</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "9",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/EmiTanji.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "My time working with Matt was collaborative, creative, and inspiring. I thoroughly enjoy his passion for the work that he does and the deep knowledge he curates as he puts himself 110% into his projects."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Emi Tanji</CardTitle>
            <h6 className=" card-category">Wizards of the Coast</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "10",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src="images/accolades/EdGreenwood.jpeg"
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Matt can guide creative projects to impressive end results—and to boot, do it while being a nice guy who's a pleasure to work with."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Ed Greenwood</CardTitle>
            <h6 className=" card-category">Creator of the Forgotten Realms, Ed Greenwood Group, Wizards of the Coast</h6>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "11",
    altText: "",
    caption: "",
  },
];

function SectionTestimonials() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div id="testimonials_anchor" className="section section-testimonials">
        <Container fluid>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Testimonials</h2>
              <h3>It has been my good fortune to work with hundreds of incredibly talented and creative people over the years. I count myself even more fortunate to have such exceptional people say a few nice things about me.</h3>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="2">
            </Col>
            <Col md="8">
              <div className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  interval={15000}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={key}
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </div>
            </Col>
            <Col className="mr-auto" md="2">
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionTestimonials;
