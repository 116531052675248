import CURRENT_DATA from "../../data/current.data";

const INITIAL_STATE = {
    collection: CURRENT_DATA
};

const currentReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        default:
            return state;
    }
};

export default currentReducer;