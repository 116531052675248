import BLOG_DATA from '../../data/blog.data';

const INITIAL_STATE = {
    collection: BLOG_DATA
};

const blogReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default blogReducer;